import { header } from '../../portfolio'
import Navbar from '../Navbar/Navbar'
import './Header.css'

const Header = () => {
  const { homepage, title } = header

  return (
    <header className='header center'>
      <div className='logo__container'>
        <svg
          xmlns='http://www.w3.org/2444/svg'
          className='Logo__Desktop'
          id='Layer_1'
          data-name='Layer 1'
          width='50.002'
          height='50.25'
          viewBox='0 0 25.002 27.25'
        >
          <path
            className='icon'
            d='M24.625,21.125H4.375V.875h20.25Zm-20-.25h19.75V1.125H4.625Z'
            transform='translate(-0.875 -0.875)'
            fill='#444'
          />
          <path
            className='icon'
            d='M23.125,23.125H2.875V2.875h20.25Zm-20-.25h19.75V3.125H3.125Z'
            transform='translate(-0.875 -0.875)'
            fill='#444'
          />
          <path
            className='icon'
            d='M21.125,25.125H.875V4.875h20.25Zm-20-.25h19.75V5.125H1.125Z'
            transform='translate(-0.875 -0.875)'
            fill='#444'
          />
          <rect
            x='6.125'
            y='14.125'
            width='4'
            height='4'
            className='icon'
            fill='#444'
          />
        </svg>
        <h3>
          {homepage ? (
            <a href={homepage} className='link'>
              <span className='title__intro'>jason</span>
              {title}
            </a>
          ) : (
            title
          )}
        </h3>
      </div>
      <Navbar />
    </header>
  )
}

export default Header
