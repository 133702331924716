const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: 'https://jasonhanshaw.com',
  title: 'hanshaw',
}

const about = {
  // all the properties are optional - can be left empty or deleted
  name: 'Jason Hanshaw',
  role: 'Marketing Technology Specialist ',
  description:
    "My expertise in the marketing technology space, coupled with my ability to consistently deliver high-quality results that meet clients' evolving needs, has allowed me to lead talented teams and remain at the forefront of the industry. With a passion for impactful solutions, I strive to provide innovative strategies that drive business growth.",
  book: 'https://www.amazon.com/Automating-Salesforce-Marketing-Cloud-productivity-ebook/dp/B09R4T18V8',
  social: {
    linkedin: 'https://www.linkedin.com/in/jason-hanshaw-developer/',
    github: 'https://github.com/invokecreate',
  },
}

const projects = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: 'InvokeCreate',
    description:
      'A Salesforce Marketing Cloud blog focused on creating solutions that both expand the capabilities of the platform and solve common use-cases.',
    stack: ['Gatsby', 'React', 'Contentful'],
    sourceCode: 'https://github.com/invokecreate',
    livePreview: 'https://invokecreate.dev',
  },
      {
    name: 'Post to CloudPage',
    description:
      'A custom activity that collects data from a personalized journey in Salesforce Marketing Cloud and integrates with CloudPages.',
    stack: ['Node','Express','JQuery','Salesforce Marketing Cloud'],
    sourceCode: 'https://github.com/PacktPublishing/Automating-Salesforce-Marketing-Cloud/tree/main/Chapter13'
  },
  {
    name: 'Salesforce Application Template',
    description:
      'A sample framework for building Salesforce Marketing Cloud applications utilizing OAuth 2.0.',
    stack: ['Node','Express'],
    sourceCode: 'https://github.com/invokecreate/example-sfmc-app',
  },
  {
    name: 'Salesforce Aura Component Template',
    description:
      'A sample Aura Framework component for accessing Salesforce Marketing Cloud data within Sales Cloud.',
    stack: ['Aura Component Framework', 'Apex', 'Javascript'],
    sourceCode: 'https://github.com/invokecreate/sf_search_send_log',
  },
]

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  'Ampscript',
  'SSJS',
  'Lightning App Development',
  'SOQL',
  'SQL',
  'Apex',
  'Python',
  'Git',
  'Ruby',
  'Sinatra',
  'JavaScript',
  'TypeScript',
  'React',
  'Vue',
]

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: 'jhanshaw.martech@gmail.com',
}

export { header, about, projects, skills, contact }
